import api from "../axios_service.js";

const fetchCargos = async () => {
  const response = await api.get("/gestor/cargos");
  return response.data.result;
};

const fetchCargo = async (id) => {
  const response = await api.get(`/gestor/cargos/${id}`);
  return response.data.result;
};

const putCargo = (id, body) => {
  return api.put(`/gestor/cargos/${id}`, body);
}

const postCargo = (body) => {
  return api.post("/gestor/cargos/add", body);
}

const syncJornadas = (body) => {
  return api.post("/gestor/sincronizar-jornadas-trabalho", body);
}

export { fetchCargos, fetchCargo, putCargo, postCargo, syncJornadas };