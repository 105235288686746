import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://brasilapi.com.br/api/cep/v1/",
});

export async function buscaCep(cep) {
  const numberPattern = /\d+/g;
  let fixCep = cep.match(numberPattern).join([]);

  const response = await axiosInstance.get(fixCep);
  return response.data;
}
